











import { Vue, Component, Prop } from 'vue-property-decorator';

import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import FormField from '@/shared/resources/components/forms/FormField.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import TextareaField from '@/shared/resources/components/forms/fields/TextareaField.vue';

@Component({
  components: {
    TextareaField,
    InputField,
    FormField,
  },
})
export default class CommentsFieldset extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;
}
